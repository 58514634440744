import React, { useEffect, useState, useRef } from 'react';
import TextFieldCharRestricted from './TextFieldCharRestricted';

import './OTPInput.scss';
import useEffectOnce from 'hooks/useEffectOnce';

export default (props) => {

  let [otpLength, setOtpLength] = useState(props.otpLength);
  let [readOnly, setReadOnly] = useState(props.readonly);

    const [otp1, setOtp1] = useState("");
    const [otp2, setOtp2] = useState("");
    const [otp3, setOtp3] = useState("");
    const [otp4, setOtp4] = useState("");
    const [otp5, setOtp5] = useState("");
    const [otp6, setOtp6] = useState("");
    const [otp7, setOtp7] = useState("");
    const [otp8, setOtp8] = useState("");

    let refOtp1 = useRef();
    let refOtp2 = useRef();
    let refOtp3 = useRef();
    let refOtp4 = useRef();
    let refOtp5 = useRef();
    let refOtp6 = useRef();
    let refOtp7 = useRef();
    let refOtp8 = useRef();

    useEffectOnce(()=>{
      refOtp1.current.focus();
    });
    
    useEffect(() => {
        //if(otp1 !== "" && otp2 !== "" && otp3 !== "" && otp4 !== "" && otp5 !== "" && otp6 !== ""  ) {
          let response = ""+otp1.toString()+""+otp2.toString()+""+otp3.toString()+""+otp4.toString()+""+otp5.toString()+""+otp6.toString()+""+otp7.toString()+""+otp8.toString()+"";
          props.onChange(response);
        //} 

    }, [otp1, otp2, otp3, otp4, otp5, otp6, otp7, otp8]);

    useEffect(()=>{
      if(props.hasErrors) {
        clearOtpValues();
      }
  }, [props.value]);

  useEffect(()=>{
    if(props.clearData) {
      clearOtpValues();
    }
}, [props.clearData]);
  

  function clearOtpValues () {
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");
    setOtp6("");
    if(otpLength === 8){
      setOtp7("");
      setOtp8("");
    }
  }

    useEffect(()=>{
        setReadOnly(props.readonly);
    }, [props.readonly]);
  
    function handleOtp1Change (value) {
      setOtp1(value);
      if(value !== "") {
        refOtp2.current.focus();
        refOtp2.current.select();
      }
    }
    function handleOtp2Change (value) {
      setOtp2(value);
      if(value !== "") {
        refOtp3.current.focus();
        refOtp3.current.select();
      }
    }
    function handleOtp3Change (value) {
      setOtp3(value);
      if(value !== "") {
        refOtp4.current.focus();
        refOtp4.current.select();
      }
    }
    function handleOtp4Change (value) {
      setOtp4(value);
      if(value !== "") {
        refOtp5.current.focus();
        refOtp5.current.select();
      }
    }
    function handleOtp5Change (value) {
      setOtp5(value);

      if(value !== "") {
        refOtp6.current.focus();
        refOtp6.current.select();
      } 
    }

    function handleOtp6Change (value) {
      setOtp6(value);

      if(value !== "") {
        refOtp7.current.focus();
        refOtp7.current.select();
      } 
    }

    function handleOtp7Change (value) {
      setOtp7(value);

      if(value !== "") {
        refOtp8.current.focus();
        refOtp8.current.select();
      } 
    }
  
  return <>
    <div className="otp-input">
      
        <TextFieldCharRestricted 
          id="otp1"
          type="text"
          onKeyUp={handleOtp1Change}
          value={otp1}
          maxCharLenght={1}
          readonly={readOnly}
          inputRef={refOtp1}
        />
      <TextFieldCharRestricted 
          id="otp2"
          type="text"
          onKeyUp={handleOtp2Change}
          value={otp2}
          maxCharLenght={1}
          readonly={readOnly}
          inputRef={refOtp2}
        />
        <TextFieldCharRestricted 
          id="otp3"
          type="text"
          onKeyUp={handleOtp3Change}
          value={otp3}
          maxCharLenght={1}
          readonly={readOnly}
          inputRef={refOtp3}
        />
        <TextFieldCharRestricted 
          id="otp4"
          type="text"
          onKeyUp={handleOtp4Change}
          value={otp4}
          maxCharLenght={1}
          readonly={readOnly}
          inputRef={refOtp4}
        />
        <TextFieldCharRestricted 
          id="otp5"
          type="text"
          onKeyUp={handleOtp5Change}
          value={otp5}
          maxCharLenght={1}
          readonly={readOnly}
          inputRef={refOtp5}
        />
        {
          otpLength !== 8 ? (
            <TextFieldCharRestricted 
              id="otp6"
              type="text"
              onKeyUp={setOtp6}
              value={otp6}
              maxCharLenght={1}
              readonly={readOnly}
              inputRef={refOtp6}
            />
          ) : (
            <>
              <TextFieldCharRestricted 
              id="otp6"
              type="text"
              onKeyUp={handleOtp6Change}
              value={otp6}
              maxCharLenght={1}
              readonly={readOnly}
              inputRef={refOtp6}
            />
            <TextFieldCharRestricted 
              id="otp7"
              type="text"
              onKeyUp={handleOtp7Change}
              value={otp7}
              maxCharLenght={1}
              readonly={readOnly}
              inputRef={refOtp7}
            />
            <TextFieldCharRestricted 
              id="otp8"
              type="text"
              onKeyUp={setOtp8}
              value={otp8}
              maxCharLenght={1}
              readonly={readOnly}
              inputRef={refOtp8}
            />
            </>
          )
        }
    </div>
  </>
}