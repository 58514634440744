import TeamImage1 from 'assets/images/themes/cadburyfc/badges/club-arsenal.svg';
import TeamImage2 from 'assets/images/themes/cadburyfc/badges/club-chelsea.svg';
import TeamImage3 from 'assets/images/themes/cadburyfc/badges/club-leeds.png';
import TeamImage4 from 'assets/images/themes/cadburyfc/badges/club-lfc.svg';
import TeamImage5 from 'assets/images/themes/cadburyfc/badges/club-manutd.svg';
import TeamImage6 from 'assets/images/themes/cadburyfc/badges/club-th--white.svg';




const teamList = [
  { 
    "id": "ARS", 
    "image": TeamImage1, 
    "type": "vertical", 
    "title": "Arsenal <br/>FC"
  },
  { 
    "id": "CHE", 
    "image": TeamImage2, 
    "type": "vertical", 
    "title": "Chelsea <br/>FC"
  },
  { 
    "id": "LEE", 
    "image": TeamImage3, 
    "type": "vertical", 
    "title": "Leeds <br/>United"
  },
  { 
    "id": "LIV", 
    "image": TeamImage4, 
    "type": "vertical", 
    "title": "Liverpool <br/>FC"
  },
  { 
    "id": "MUN", 
    "image": TeamImage5, 
    "type": "vertical", 
    "title": "Manchester <br/>United"
  },
  { 
    "id": "TOT", 
    "image": TeamImage6, 
    "type": "vertical", 
    "title": "Tottenham <br/>Hotspur"
  },
  
]

export default teamList;