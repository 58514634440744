import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import analyticsService from 'services/analyticsService';
import ActionBar from 'containers/ActionBar/ActionBar';
import Button from 'components/Button/Button';
import TeamList from 'utils/teams';
import ExperienceList from 'utils/experiences';
import PrizeBlock from 'components/Prize/PrizeBlock';
import './PrizeSelection.scss';
import Carousel from 'components/Carousel/Carousel';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';


export default () => {
  let competitionEntryContext = useCompetitionEntryContext();
  let history = useHistory();
  const [teamSelected, setTeamSelected] = useState(false);
  const [nextScreen, setNextScreen] = useState(false);
  const [team, setTeam] = useState("");
  const [experience, setExperience] = useState("");

  const debounce = (func, delay) => { 
    let debounceTimer; return function(...args) { 
      clearTimeout(debounceTimer); 
      debounceTimer = setTimeout(() => func(...args), delay); 
  }}

  const onContinue = (chosenTeam, chosenExperience) => {
    if (team !== null && experience !== null) {
      competitionEntryContext.setExperience(chosenExperience);
      competitionEntryContext.setTeam(chosenTeam);                  

      analyticsService.logEvent("button_click", "prize_selection", {
        location: "prize_selection_page",
        label: "selected prize " + chosenTeam,
        url: "/prize-select"
      });

      analyticsService.logEvent("button_click", "experience_selection", {
        location: "prize_selection_page",
        label: "selected experience " + chosenExperience,
        url: "/prize-select"
      });

      document.body.classList.remove('bg-gold');
      history.push("/registration");
    }
  }

  
  useEffect(() => {
    if (nextScreen) {
      document.body.classList.add('bg-gold');
    }
  
    return () => {
      document.body.classList.remove('bg-gold');
    };
  }, [nextScreen]);


  const handleTeamSelection = useCallback(debounce((e, teamId) => {
      if (teamId !== null) {
          setTeam(teamId);
          setTeamSelected(true);
      }
  }, 200), [setTeam, setTeamSelected]);

  const handleExperienceSelection = useCallback(debounce((e, experienceId) => {
    if(experienceId !== null) {
      setExperience(experienceId)
    }
  }, 200), [setExperience]);

  const handleNextScreen = (e) => {
      setNextScreen(true)
  }


  /* RENDER */
  return <>
    
    <Helmet>
      <meta charSet="utf-8" />
      <title>Prize Selection | Cadbury FC - The Winning Pass</title>
    </Helmet>
    {nextScreen ? (

          <div className="layout" tabIndex="-1">
            <section className="layout-wrapper layout-general layout-general--full home__prize-block-section">
              <div className="layout-container layout-container--wide layout-container--center">
                <div className="home__prize-blocks home__prize-blocks--select">
                <h2 className="type-h1 type-h1--purple">Pick Your Experience</h2>
                <Carousel 
                    className="home__prize-blocks_carousel"
                    params={{
                      watchOverflow: true,
                      spaceBetween: 30,
                      slidesPerView: 1,
                      centeredSlides: false,
                      pagination: {
                        el: '.swiper-pagination3',
                        clickable: true
                       },
                      navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                      },
                      runCallbacksOnInit: true,
                      slidesPerGroup: 1,
    
                      breakpoints: {
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                          slidesPerGroup: 1,
                        },
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 0,
                          slidesPerGroup: 3,
                        }
                      }
                    }}
                    >

                      
                       { ExperienceList.map((p, index) => (
                        

                        <PrizeBlock
                          id={p.id}
                          tab={index == 0 ? 0 : index + 1}    
                          imageSmall={p.id !== "VIP"}
                          imageMiddle={p.id === "FAN"}
                          lastChild={p.id === "TOUR"}
                          prize={p}         
                          model="prize"
                          selected={experience && experience === p.id}
                          selectable 
                          description={p.description}
                          image={p.image}
                          title={p.title}
                          alt={p.title + " badge"}
                          onChange={handleExperienceSelection}
                        /> 
                        ))

                      }
          
                  </Carousel>

                  <div className='home__prize-blocks__nav home__prize-blocks__nav--gold'>
                    <div className='swiper-pagination3'></div>
                  </div>
                  
       
                </div>
            </div>
          </section>


         
              <ActionBar gold show={experience !== ""}>
              <Button 
              alt
              wide 
              title={"Submit"}
              onClick={() => onContinue(team, experience)}
              />
              </ActionBar>
          
        </div>
    ) : (
      <div className="layout" tabIndex="-1">
       <section className="layout-general layout-general--full layout-wrapper overflowx-hidden">
       
       <div className="layout-container layout-container--center layout-container--wide prize-select" >
       <h1 className="type-h1">Choose Your Club</h1>
       <p>
         Which club matchday experience would you like to get your hands on?
       </p>
         <div className="prize-select__list">

         <Carousel  
                 className="prize-select__carousel"
                 params={{
                   spaceBetween: 30,
                   slidesPerView: 1,
                   centeredSlides: false,
                   pagination: {
                    el: '.swiper-pagination2',
                    clickable: true
                    //type: 'fraction'
                   },
                   navigation: {
                     nextEl: '.swiper-button-next',
                     prevEl: '.swiper-button-prev'
                   },
                   runCallbacksOnInit: true,
                   slidesPerGroup: 1,
 
                   breakpoints: {
                    992: {
                      pagination: {
                        type: 'fraction'
                       },
                      slidesPerView: 4,
                      spaceBetween: 55,
                      slidesPerGroup: 4,
                    },
                    1200: {
                      pagination: {
                        type: 'fraction'
                       },
                      slidesPerView: 4,
                      spaceBetween: 60,
                      slidesPerGroup: 4
                    },
                    1300: {
                      pagination: {
                        type: 'fraction'
                       },
                      slidesPerView: 4,
                      spaceBetween: 20,
                      slidesPerGroup: 4
                    }
                   }
                 }}
                 >
           {
             TeamList.map((p, index) => (
               
                 <PrizeBlock
                  id={p.id}
                  tab={index == 0 ? 0 : index + 1}
                  prize={p}                   
                  model="prize"
                  selected={team && team === p.id}
                  selectable 
                  select
                  image={p.image}
                  title={p.title}
                  alt={p.title + " badge"}
                  onChange={handleTeamSelection}      
                 />

             ))
           }       

               </Carousel>
         </div>
       <div className='home__prize-blocks__nav'>
           <button type="button" className='swiper-button-prev'></button>
           <div className='swiper-pagination2'></div>
           <button type="button"  className='swiper-button-next'></button>
       </div>

       <p>Prize draw will take place after 21.03.25.</p>
     </div>
       

     </section>
      
          <ActionBar 
          show={teamSelected}>
          <Button 
          wide 
          title={"Continue"}  
          onClick={handleNextScreen}
          />
          </ActionBar>
      
    </div>
    )
    }

  </>;
}