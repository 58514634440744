import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useParams, useNavigate, Redirect } from 'react-router-dom';

import analyticsService from 'services/analyticsService';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import accountService from 'services/accountService';
import accountResetPasswordValidator from 'validators/accountResetPasswordValidator';
import useEffectOnce from 'hooks/useEffectOnce';
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';
import TextField from 'components/Forms/TextField';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.svg';
import Form, { createOnChangeHandler } from './Form';


import './Form.scss';

export default () => {
  let settingsContext = useSettingsContext();  

  let [settings, setSettings] = React.useContext(SettingsContext);
  
  let [complete, setComplete] = useState(false);
  let [token, setToken] = useState('');
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [confirmNewPassword, setConfirmNewPassword] = useState('');
  let [recaptcha2, setRecaptcha2] = useState(null);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });  

  const createCommand = () => {
    return {      
      password,
      oobCode: token,      
      confirmNewPassword,
      country: "ROI",
      recaptcha2
    };
  }

  const onFormSuccess = async (result) => {
    analyticsService.logEvent("forms", "Forms", {
      action: "ForgottenPasswordSuccess"
    });

    setComplete(true);
  }

  const onFormFail = (error) => {
    analyticsService.logEvent("forms", "Forms", {
      action: "ForgottenPasswordFail",
      label: error
    });
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }

  useEffectOnce(() => {
    const parsedUrl = new URLSearchParams(window.location.search);
    setToken(parsedUrl.get('oobCode'));
    
    /* We set the country state to ROI so the user can go back to forgotten password page in case something goes wrong, 
    considering we only handle password reset on our side for ROI */
    sessionStorage && sessionStorage.setItem('cadburyfcwinningpass.country', 'ROI');
    settingsContext.setCountry('ROI');

    window.setTimeout(function(){
      try 
      {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {
          
            window.grecaptcha.ready(() => {
                window.grecaptcha.render($grecaptchav2, {
              'sitekey' : '6LddK3YqAAAAACHBZAH1k3cQtpnsV5jjMOheeFJ-',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
                });
          });
        }
      }catch{}
    }, 1000);
  });

  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }

  /* RENDER */
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Reset password | Cadbury FC - The Winning Pass</title>
    </Helmet>
    <section className="layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center">
        <h1 className="type-h1">Reset your password</h1>

        { !complete ? (
          <>
            <p className="form__intro">Reset password for account: {email}</p>

            <Form 
              id="passwordResetComplete"
              formClass="form form--slim"
              submitTitle="Submit"
              onSuccess={onFormSuccess}
              onFail={onFormFail}
              command={{
                model: createCommand,
                service: accountService.forgottenPasswordComplete
              }}
              validation={{
                validator: accountResetPasswordValidator,
                onValidation: setValidation
              }}
            >
              
              { validation.errors.hasErrors && validation.acknowledged &&
                <ValidationSummary 
                  errors={validation.errors}
                  handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
                />
              }

              <fieldset className="form__fieldset">
                <TextField 
                  label="New password"
                  id="password"
                  type="password"
                  onChange={createOnChangeHandler(validation, 'password', setPassword)}
                  value={password}
                  hasErrors={!!validation.errors.password}
                />
                <ValidationMessage errors={validation.errors.password} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="Confirm password"
                  id="confirmNewPassword"
                  type="password"
                  onChange={createOnChangeHandler(validation, 'confirmNewPassword', setConfirmNewPassword)}
                  value={confirmNewPassword}
                  hasErrors={!!validation.errors.confirmNewPassword}
                />
                <ValidationMessage errors={validation.errors.confirmNewPassword} />
              </fieldset>

              <fieldset className="form__fieldset">
                  
                  <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
                  <div class="g-recaptcha-v2" data-sitekey="6LddK3YqAAAAACHBZAH1k3cQtpnsV5jjMOheeFJ-"
                  data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
                  <ValidationMessage errors={validation.errors.recaptcha2} /> 

              </fieldset>

              { validation.errors.hasErrors && !validation.acknowledged &&
                <Modal 
                  panel 
                  hideClose
                  panelBackground
                  icon={AlertIcon}
                  iconTooltip="Alert"
                  onDismiss={onDismissModal}
                  onConfirm={onDismissModal}
                  title="Oops">
                  <ValidationSummary 
                    modal
                    defaultMessage="Please check you have entered all fields correctly."
                    errors={validation.errors}
                    handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
                  />
                  { !validation.errors.globalErrors.length ? (
                      <Button title="Try again" onClick={onDismissModal} />
                    ) : (                      
                      <Button title="Back to Reset Page" href="/forgotten-password" />
                      
                    )
                  }                  
                </Modal>
              }
            </Form>
          </>
        ):(
          <p className="form__intro">
            Your new password has been saved.<br /><br />
            
            <Link 
            analytics={{evnt: "link_click", category: "internal_link", location: "reset password confirmation form", label: "home page"}}
            to="/" title="Back to Home Page" >Back to Home Page</Link>
          </p>
          
        )}

      </div>
    </section>
  </>;
}
