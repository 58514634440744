import React from 'react';
import classList from 'react-classlist-helper';
import './CheckToggler.scss';


import analyticsService from 'services/analyticsService';

export default (props) => {
  const classMap = {
    "check-toggler": true,
    [props.className]: !!props.className,
  };

  function onChange(event) {
    if (props.onChange) {
      
      if(props.checkTogglerAnalytics && event.target.checked === true){
        var checkTitle = event.nativeEvent.target.title;
        var analytics = props.checkTogglerAnalytics;

        analyticsService.logEvent(analytics.evnt, analytics.category, {
          location: analytics.location ? analytics.location : "",
          label: analytics.label ? analytics.label : checkTitle,
          url: analytics.url ? analytics.url : ""
        });


        props.onChange(event.target.checked);

      } else {
        props.onChange(event.target.checked);
      }

    }
  }

  return (
    <div className={classList(classMap)} >
      
      <input 
        className="checkbox__input"
        type="checkbox" 
        id={props.id} 
        name={props.id}
        onChange={onChange}
        title={props.label}
      />
      <div className="check-toggler__content">
        <div className="checkbox__fake">
          <span className="checkbox__focus"></span>
        </div>
        <label className="checkbox__label" htmlFor={props.id} dangerouslySetInnerHTML={{ __html: props.label}}></label>


        <div className="check-toggler__copy">
          { props.children }
        </div>
      </div>
        
      
    </div>
  )
  
}