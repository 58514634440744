import React, {useEffect} from 'react';
import classList from 'react-classlist-helper';
import './PrizeBlockDouble.scss';

const PrizeBlockDouble = (props) => {

  const mainClassMap = {
      "prize-block-double": true,
  };


  return (
    <article className={classList(mainClassMap)}>
      <h2 className="prize-block-double__title type-h1"  dangerouslySetInnerHTML={{__html: props.title}}></h2>
      <div className="prize-block-double__info">
        
          <div className="prize-block-double__image  prize-block-double__image--large">
            <picture>
              { props.image && props.imageLarge &&
                <source srcSet={props.imageLarge + " 2x, " + props.image + " 1x"} media="(min-width: 768px)" />
              }
              {props.imageMobLarge &&
                <source srcSet={props.imageMobLarge + " 2x, " + props.imageMob + " 1x"} />
              }
              <img src={props.imageMob} alt={props.alt || props.title} />
            </picture>
          </div>
          
      </div>
      <div className="prize-block-double__info">
      
          <div className="prize-block-double__image">
            <picture>
              { props.image2 && props.image2Large &&
                <source srcSet={props.image2Large + " 2x, " + props.image2 + " 1x"} media="(min-width: 768px)" />
              }
              {props.image2MobLarge &&
                <source srcSet={props.image2MobLarge + " 2x, " + props.image2Mob + " 1x"} />
              }
              <img src={props.image2Mob} alt={props.alt || props.title} />
            </picture>
          </div>
      </div>
    </article>
  )
}

export default PrizeBlockDouble;
