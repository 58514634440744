import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useEffectOnce from 'hooks/useEffectOnce';
import analyticsService from 'services/analyticsService';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import competitionEntryValidator from 'validators/competitionEntryValidator';
import accountService from 'services/accountService';

import Button from 'components/Button/Button';
import Checkbox from 'components/Forms/Checkbox';
import TextField from 'components/Forms/TextField';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';
import SelectField from 'components/Forms/SelectField';
import DateInput from 'components/Forms/DateInput';
import Link from 'components/Link/Link';


import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import LoginForm from 'containers/Forms/LoginForm';
import AlertIcon from 'assets/images/alert.svg';
import Form, { createOnChangeHandler } from './Form';
import CheckToggler from 'components/CheckToggler/CheckToggler';


import './Form.scss';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';

export default () => {
  let history = useHistory();
  let competitionEntryContext = useCompetitionEntryContext();
  let settingsContext = useSettingsContext();
  let compState = settingsContext.competitionStatus.openState;
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let minBirthDate = new Date();
  minBirthDate.setYear(minBirthDate.getFullYear() - 18);

  let [settings, setSettings] = React.useContext(SettingsContext);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [email, setEmail] = useState('');
  let [emailConfirm, setEmailConfirm] = useState('');
  let [phoneNumber, setPhoneNumber] = useState('');
  let [teamCode, setTeamCode] = useState(null);
  let [accountOptIn, setAccountOptIn] = useState(false);
  let [password, setPassword] = useState('');
  let [passwordConfirm, setPasswordConfirm] = useState('');
  let [retailer, setRetailer] = useState(settings.country === "ROI" ? '0': null);
  let [terms, setTerms] = useState(false);
  let [legalAge, setLegalAge] = useState(false);
  let [dob, setDoB] = useState({
    isValid: false
  });

  let [optIn, setOptIn] = useState(false);
  let [teamOptIn, setTeamOptIn] = useState(false);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [recaptcha2, setRecaptcha2] = useState(null);
  const [firstNameReadOnly, setFirstNameReadOnly] = useState(false);
  const [lastNameReadOnly, setLastNameReadOnly] = useState(false);
  const [emailReadOnly, setEmailReadOnly] = useState(false);
  const [dobReadOnly, setDobReadOnly] = useState(false);
  const [teamCodeReadOnly, setTeamCodeReadOnly] = useState(false);
  const [phoneNumberReadOnly, setPhoneNumberReadOnly] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [shuffledRetailerList, setShuffledRetailerList] = useState([]);


  let [showValidationModal, setShowValidationModal] = useState(false);
  let [showCustomErrorButton, setShowCustomErrorButton] = useState(false);
  


  useEffectOnce(() => {   
    //This is needed when a user registered and tries to enter the competition again without refreshing the page
    if (settings.isLoggedIn && !settings.account) {
      getAccountDetails();
    }
    
  });

  useEffect(() => {
    let account = settings.account;
    if (!account) return;


    if (account.firstname) {
      setFirstName(account.firstname);
      setFirstNameReadOnly(true);
    }

    if (account.lastname)  {
      setLastName(account.lastname);
      setLastNameReadOnly(true);
    }

    if (account.email) {
      setEmail(account.email);
      setEmailConfirm(account.email);
      setEmailReadOnly(true);
    }

    if (account.phoneNumber) {
      setPhoneNumber(account.phoneNumber);
      setPhoneNumberReadOnly(true);
    }

    if (account.birthday) {
      setDoB({
        date: new Date(account.birthday),
        isValid: true
      });
      setDobReadOnly(true);
    }

    setOptIn(account.emailList);
    setTeamOptIn(account.thirdparty);    

  }, [settings.account])

  useEffect(()=>{

    if(validation.errors.hasErrors && !validation.acknowledged){
      setShowValidationModal(true);

      if(validation.errors.globalErrors && validation.errors.globalErrors.length > 0){
        if(validation.errors.globalErrors[0].indexOf("CADERR//") > -1){
          setShowCustomErrorButton(true);
        }
      } else {
        setShowCustomErrorButton(false);
      }
      
    } else {
      setShowValidationModal(false);
    }
    
  }, [validation])


  useEffect(()=>{
    
    var sett_account = settings.account;    
    if(sett_account === null || sett_account === undefined){
      setDoB(null);
    } else {

      if (!sett_account.birthday) {
        setDoB(null);
      }
    }
   
  }, [optIn])
  
  const createCommand = () => {
    let model = {
      firstName,
      lastName,
      email,
      emailConfirm,
      country: "GB",
      phoneNumber,
      dob,
      retailerId: retailer,
      terms,
      legalAge,
      barcode: competitionEntryContext.barcode,
      batchcode: competitionEntryContext.batchcode,      
      tier1PrizePublicId: competitionEntryContext.team,
      tier1Experience: competitionEntryContext.experience,
      teamCode,
      optIn,
      teamOptIn,
      accountOptIn,
      password,
      passwordConfirm,
      recaptcha2,
      phaseId: compPhaseId
    };

    if (dob && dob.isValid) {
      Object.assign(model, {
        dateOfBirthDay: dob.date.getDate(),
        dateOfBirthMonth: dob.date.getMonth() + 1,
        dateOfBirthYear: dob.date.getFullYear(),
        dob
      })
    }

    return model;
  }

  const retailersList = [
    { "value": 2, "name": "Sainsburys"},
    { "value": 3, "name": "Coop"},
    { "value": 4, "name": "Asda"},
    { "value": 5, "name": "Morrisons"},
    { "value": 6, "name": "Tesco"},
    { "value": 7, "name": "Spar"},
    { "value": 8, "name": "Budgens"},
    { "value": 9, "name": "Londis"},
    { "value": 10, "name": "WHSmith"},
    { "value": 11, "name": "Waitrose"},
    { "value": 12, "name": "Nisa"},
    { "value": 13, "name": "Costcutter"},
    { "value": 14, "name": "Independent Retailer"},
  ];

  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }
  

  useEffectOnce(() => {
    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);
    setShuffledRetailerList(shuffle(retailersList));

    window.setTimeout(function(){
      try 
      {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {
          
            window.grecaptcha.ready(() => {
                window.grecaptcha.render($grecaptchav2, {
              'sitekey' : '6LddK3YqAAAAACHBZAH1k3cQtpnsV5jjMOheeFJ-',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
                });
          });
        }
      }catch{}
  }, 1000);
    
  });

  async function getAccountDetails() {      
      accountService
        .getAccountDetails()
        .then((response) => {
          let account = response.accountDetails;
          if (account) {
            setSettings({ ... settings, account });
          }           
        })
    
  }

  const onFormSuccess = (result) => {

    analyticsService.logEvent("signup", "entry_registration_submit", {
      location: "entry_registration_form",
      label: "entry registration success", 
      url: "/registration"
    });
  
    // forward to thank-you page
    competitionEntryContext.reset();
    competitionEntryContext.setEmail(email);
    competitionEntryContext.setResult(result);   

    // Update csfr token and flag as logged in
    setSettings({...settings, prize: "", isLoggedIn: settings.isLoggedIn? true : accountOptIn });

    let urlData =  `/${result.entryPublicId}`;
    //let urlData = !competitionIsMopup ? `/${result.entryPublicId}` : '';
    history.push(`/thankyou${urlData}`);
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }

  const onShowLoginModal = () => {
      setShowLoginModal(true);
  }

  const onHideLoginModal = () => {
    setShowLoginModal(false);
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }

  const onLoginStateChange = (state) => {
    if (state.isLoggedIn) {
      setShowLoginModal(false);
       /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
    }
  }

  /* RENDER */
  return <>  
    <Helmet>
      <meta charSet="utf-8" />
      <title>Register | Cadbury FC - The Winning Pass</title>
    </Helmet>
    <section className="layout layout-general layout-general--full layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center">
        <h1 className="type-h1">Your Details</h1>

        <div className="form__intro">
          <p >
          We just need some additional information from you to check that you’re eligible to enter and so we can contact you if you’re one of the lucky winners!
          </p>
          {
          !settings.isLoggedIn &&
            <p>
              If you already have a Cadbury FC account, please log in <Button className="button--text-link" title="here" onClick={onShowLoginModal} analytics={{evnt: "link_click", category: "internal_link", location: "entry_registration_form", label: "login modal"}} />.
            </p>} 
          <p>
            Fields marked '*' are required.
          </p>
        </div>
        <Form 
          id="registration"
          submitTitle="Save Details"
          onSuccess={onFormSuccess}
          formClass="form form--slim"
          command={{
            model: createCommand,
            service: competitionEntryService.addCompetitionEntry
          }}
          validation={{
            validator: competitionEntryValidator,
            onValidation: setValidation
          }}
          submitAnalytics={[
            {evnt: "button_click", category: "entry_registration_submit", location: "entry_registration_form", label: "email capture", url: email},
            (dob && dob.isValid) &&
            {evnt: "button_click", category: "entry_registration_submit", location: "entry_registration_form", label: "date of birth capture", url: dob.date},
          ]}
        >
          
          { validation.errors.hasErrors && validation.acknowledged &&
            <ValidationSummary 
              errors={validation.errors}
              handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
            />
          }

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted 
              label="First name *"
              id="firstName"
              placeholder="e.g. John"
              onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
              value={firstName}
              hasErrors={!!validation.errors.firstName}
              readonly={firstNameReadOnly}
              maxCharLength={60}
            />
            <ValidationMessage errors={validation.errors.firstName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted 
              label="Last name *"
              id="lastName"
              placeholder="e.g. Smith"
              onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
              value={lastName}
              hasErrors={!!validation.errors.lastName}
              readonly={lastNameReadOnly}
              maxCharLength={60}
            />
            <ValidationMessage errors={validation.errors.lastName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted 
              label="Email *"
              id="email"
              placeholder="Your email address"
              type="text"
              inputMode="email"
              onChange={createOnChangeHandler(validation, 'email', setEmail)}
              value={email}
              hasErrors={!!validation.errors.email}
              readonly={emailReadOnly}
              maxCharLength={150}
            />
            <ValidationMessage errors={validation.errors.email} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted 
              label="Email confirm *"
              id="emailConfirm"
              placeholder="Your email address"
              autoComplete="off"
              type="text"
              inputMode="email"
              onChange={createOnChangeHandler(validation, 'emailConfirm', setEmailConfirm)}
              value={emailConfirm}
              hasErrors={!!validation.errors.emailConfirm}
              readonly={emailReadOnly}
              maxCharLength={150}
            />
            <ValidationMessage errors={validation.errors.emailConfirm} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField 
              label="Phone number"
              id="phoneNumber"
              placeholder="Your phone number"
              info={`Please ensure this is a valid UK mobile number`}
              type="tel"
              onChange={createOnChangeHandler(validation, 'phoneNumber', setPhoneNumber)}
              value={phoneNumber}
              autoComplete="off"
              hasErrors={!!validation.errors.phoneNumber}
              readonly={phoneNumberReadOnly}
              onDismiss={onDismissModal}
            />
            <ValidationMessage errors={validation.errors.phoneNumber} />
          </fieldset>

          
          
            <fieldset className="form__fieldset"> 
              <SelectField 
                label="Where did you buy your product from? *"
                id="retailerId"
                value={retailer}
                onChange={createOnChangeHandler(validation, 'retailerId', setRetailer)}
                hasErrors={!!validation.errors.retailerId}
                selectAnalytics={{evnt: "form_field", category: "form_field_dropdown", location: "entry_registration_form", url: retailer}}
              >
                <option defaultValue hidden >Select an option</option>

                {
                  shuffledRetailerList.map((p, index) => (
                    <option value={p.name} key={"retailer-"+index}>{p.name}</option>
                  ))
                }
                <option value="1">Other</option>
                
              </SelectField>
              <ValidationMessage errors={validation.errors.retailerId} />
            </fieldset>
          
      
          <fieldset className="form__fieldset">
          
            <SelectField 
              label="Please select your preferred club *"
              id="teamCode"
              placeholder="Select an option"
              onChange={createOnChangeHandler(validation, 'teamCode', setTeamCode)}
              selectAnalytics={{evnt: "form_field", category: "form_field_dropdown", location: "entry_registration_form", url: teamCode}}
              value={teamCode}
              hasErrors={!!validation.errors.teamCode}
              readonly={teamCodeReadOnly}
            >
              <option defaultValue hidden >Select an option</option> 
              <option value="ARS" selected={teamCode === "ARS"}>Arsenal FC</option>
              <option value="AVA" selected={teamCode === "AVA"}>Aston Villa Women’s FC</option>
              <option value="BRC" selected={teamCode === "BRC"}>Birmingham City</option>
              <option value="CEL" selected={teamCode === "CEL"}>Celtic</option>
              <option value="CHE" selected={teamCode === "CHE"}>Chelsea FC</option>
              <option value="GRF" selected={teamCode === "GRF"}>Grenfell Athletic</option>
              <option value="LEE" selected={teamCode === "LEE"}>Leeds Utd</option>
              <option value="LIV" selected={teamCode === "LIV"}>Liverpool FC</option>
              <option value="MUN" selected={teamCode === "MUN"}>Manchester United</option>
              <option value="RFC" selected={teamCode === "RFC"}>Rangers</option>
              <option value="TOT" selected={teamCode === "TOT"}>Tottenham Hotspur</option>
              <option value="OTH" selected={teamCode === "OTH"}>Other</option>
            </SelectField>
            <ValidationMessage errors={validation.errors.teamCode} />
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox 
              id="optIn" 
              label="I want to receive emails packed with the latest news, competitions & offers from Cadbury. You can unsubscribe at any time."
              onChange={setOptIn} 
              value={optIn}
              checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "entry_registration_form", url: optIn}}
            />

            {
              optIn && 
              <>
              <br/>
                <div className='form__date-entry'>
                  <label
                    className="textfield__label textfield__label--dob"
                    htmlFor="">
                    Fancy a birthday treat? Tell us your DOB and keep an eye on your inbox on the big day!
                  </label>
                  <br/>
                  <p><strong>Date of birth</strong></p>
                  <DateInput
                    className="date-select"
                    minDate={minBirthDate}
                    minDateErrorMessage="You must be over 18 to enter this promotion"
                    onChange={createOnChangeHandler(validation, 'dob', setDoB)}
                    value={dob}
                    readonly={dobReadOnly}
                  />
                  <ValidationMessage errors={validation.errors.dateOfBirthDay || validation.errors.dob} />
                </div>
                </>
            }
          </fieldset>

          { !settings.isLoggedIn &&
            <fieldset className="form__fieldset">
              <div className="form__fieldset__title">Cadbury FC Account</div>
             
             <CheckToggler 
             id="reg_create_acc"
             onChange={setAccountOptIn}
             label="Add a password to create a Cadbury FC account. Cadbury FC account holders who have also subscribed to Cadbury emails by ticking the email permission box above will also be in with additional chances to win match day tickets & other football goodies throughout the year. Find out more about Cadbury FC <a href='https://www.cadbury.co.uk/cadburyfc/' target='_blank' rel='noreferrer noopener'>here</a>."
             checkTogglerAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "entry_registration_form", label: "Create a Cadbury FC account", url: accountOptIn}}
             >
               <fieldset className="form__fieldset">
                  <TextField 
                    label="Password"
                    info="Your password must be at least 8 characters long and include at least one upper case letter, one lower case letter and one digit."
                    id="password"
                    type="password"
                    onChange={createOnChangeHandler(validation, 'password', setPassword)}
                    value={password}
                    hasErrors={!!validation.errors.password}
                  />
                  <ValidationMessage errors={validation.errors.password} />
                </fieldset>

                <fieldset className="form__fieldset">
                  <TextField 
                    label="Confirm password"
                    id="passwordConfirm"
                    type="password"
                    onChange={createOnChangeHandler(validation, 'passwordConfirm', setPasswordConfirm)}
                    value={passwordConfirm}
                    autoComplete="off"
                    hasErrors={!!validation.errors.passwordConfirm}
                  />
                  <ValidationMessage errors={validation.errors.passwordConfirm} />
                </fieldset>
               
             </CheckToggler>

                
            </fieldset>
          }

          <fieldset className="form__fieldset form__fieldset--space-top">
            <Checkbox 
              id="legalAge" 
              label="* I confirm I am over 18 years old."
              hasErrors={validation.errors.hasErrors && !legalAge}
              onChange={createOnChangeHandler(validation, 'legalAge', setLegalAge)} 
              checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "entry_registration_form", url: legalAge}}
            />
            <ValidationMessage errors={validation.errors.legalAge} />            
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox 
              id="terms" 
              label="* I've read and agree to The Winning Pass promotion &nbsp;<a href='/terms-and-conditions' rel='noreferrer noopener' target='_blank'>Terms and Conditions</a>&nbsp; and <a href='https://eu.mondelezinternational.com/privacy-notice?siteId=XHFQX%2fQ3Cy2O4CZXDnCZrIoQ7zN49xjEjvQkCn%2bzhGY%3d' rel='noreferrer noopener' target='_blank'>Privacy Policy</a>."
              hasErrors={validation.errors.hasErrors && !terms}
              onChange={createOnChangeHandler(validation, 'terms', setTerms)} 
              checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "entry_registration_form", url: terms}}
            />
            <ValidationMessage errors={validation.errors.terms} /> 

            <Checkbox 
              id="teamOptIn" 
              label="I give Cadbury permission to use my personal information to help ensure future communications, either through partners (including my preferred club) or across third party platforms, are more relevant & tailored to me. Cadbury is an affiliated brand of Mondelez International."
              onChange={setTeamOptIn} 
              value={teamOptIn}
              checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "entry_registration_form", url: teamOptIn}}
            />   
          </fieldset>



          <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
          <div className="g-recaptcha-v2" data-sitekey="6LddK3YqAAAAACHBZAH1k3cQtpnsV5jjMOheeFJ-"
          data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
          <ValidationMessage errors={validation.errors.recaptcha2} /> 


          { showValidationModal &&
            <Modal 
              panel 
              error
              hideClose
              show={showValidationModal}
              icon={AlertIcon}
              alt="Warning sign"
              onDismiss={onDismissModal}
              onConfirm={onDismissModal}
              title="Oops">
              <ValidationSummary 
                modal
                defaultMessage="Please check you have entered all fields correctly."
                errors={validation.errors}
                handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
              />
              { !validation.errors.globalErrors.length ? (
                  <Button title="Try again" onClick={onDismissModal} />
                ) : (
                  showCustomErrorButton ? 
                   ( <Link 
                      analytics={{evnt: "link_click", category: "main_cadbury_site", location: "registration error modal", label: "Cadbury FC competitions"}}
                      to="https://www.cadbury.co.uk/cadburyfc/competitions/"
                      title="Discover More" lozenge >
                        Discover More
                  </Link>
                  )
                  : 
                  (<Button title="Back to homepage" href="/" />)
                )
              }
            </Modal>
          }
        </Form>

      </div>

      { showLoginModal &&
        <Modal 
          panel 
          onDismiss={onHideLoginModal}
          onConfirm={onHideLoginModal}
          title="Log in"
          closeAnalytics={{evnt: "button_click", category: "close_modal", location: "login_modal", label: "close login modal"}}
          >
          <LoginForm onStateChange={onLoginStateChange} />
          <p className="modal__footer text-center">

          <Link 
          analytics={{evnt: "link_click", category: "main_cadbury_site", location: "login_modal", label: "forgotten password external page"}}
          href="https://www.cadbury.co.uk/cadburyfc/account/forgotten-password/" target="_blank" title="Forgotten your password?" >Forgotten your password?
          </Link>
            
          </p>
          
        </Modal>
      }
    </section>
    
    <SummaryTsandCs />
  </>;
}