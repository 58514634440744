import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';


import ValidationErrorCollection from 'validators/ValidationErrorCollection';

import CodeEntry from './CodeEntry';
import PrizeBlock from 'components/Prize/PrizeBlock';
import PrizeBlockDouble from 'components/Prize/PrizeBlockDouble';
import Step from 'components/Step/Step';
import Button from 'components/Button/Button';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';
import Carousel from 'components/Carousel/Carousel';
import Link from 'components/Link/Link';

import KeyVisual from './KeyVisual';

import How1 from 'assets/images/campaign/how-1.png';
import How1_2x from 'assets/images/campaign/how-1@2x.png';
import How2 from 'assets/images/campaign/how-2.png';
import How2_2x from 'assets/images/campaign/how-2@2x.png';
import How3 from 'assets/images/campaign/how-3.png';
import How3_2x from 'assets/images/campaign/how-3@2x.png';
import How4 from 'assets/images/campaign/how-4.png';
import How4_2x from 'assets/images/campaign/how-4@2x.png';
import How5 from 'assets/images/campaign/how-5.png';
import How5_2x from 'assets/images/campaign/how-5@2x.png';

//PRIZES
import Tickets from 'assets/images/campaign/prizes/tickets_mob.png';
import Tickets2x from 'assets/images/campaign/prizes/tickets_mob@2x.png';
import TicketsDesk from 'assets/images/campaign/prizes/tickets_desk.png';
import TicketsDesk2x from 'assets/images/campaign/prizes/tickets_desk@2x.png';
import Vouchers from 'assets/images/campaign/prizes/vouchers_Mob.png';
import Vouchers2x from 'assets/images/campaign/prizes/vouchers_Mob@2x.png';
import VouchersDesk from 'assets/images/campaign/prizes/vouchers_desk.png';
import VouchersDesk2x from 'assets/images/campaign/prizes/vouchers_desk@2x.png';
import VIPStar from 'assets/images/campaign/VIP.svg';
import FANS from 'assets/images/campaign/FAN.svg';
import GATES from 'assets/images/campaign/TOUR.svg';


import analyticsService from 'services/analyticsService';
import './Home.scss';

export default () => {
  const [settings, setSettings] = React.useContext(SettingsContext);
  let settingsState = useSettingsContext();
  let history = useHistory();
  let compState = settings.competitionStatus.openState;
  let competitionIsOpen = compState === "Open";
  let competitionIsNotStarted = compState === "NotStarted";
  let competitionIsClosed = compState === "Closed";
  const [showEntryModal, setShowEntryModal] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(!settings.country);

  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  const handleEnterNow = async (comp) => {
    
    analyticsService.logEvent("button_click", "site_modal", {
      location: comp,
      label: "proof of purchase modal",
      url: ""
    });

      setShowEntryModal(true);
  } 
  

  const renderSteps = (steps) => {
    return steps.map((step, index) => {
      return (
        <Step key={step.number} {...step} />
      )
    })
  }

  const renderEntryCta = (title, component) => {

    return <Button
      wide
      type="submit"
      title={title ? title : "Enter Now"}
      onClick={e => handleEnterNow(e, component)}
    />
  }

  const renderIntroMessage = (compState) => {
    switch (compState) {
      case 'Open':
        return <>
        
            <div className="home__intro home__intro--medium">
              <h2 className='type-h1 '>Win the Ultimate Matchday experience and share it with three of your friends</h2>
              <div className="goldbar"></div>
              <div className="home__intro-body">
                <p>Cadbury are giving you the chance to choose YOUR dream matchday experience, whether that's as a VIP, a fan in the stands or with behind the scenes access - and share it with those you love most. It's all to play for. Each experience comes with a luxury hotel stay, free travel, meeting a football legend and much more. What a score!</p>
                <p>There's also 100s of pairs of match day tickets up for grabs to watch Arsenal FC, Celtic FC, Chelsea FC, Leeds United FC, Liverpool FC, Manchester United, Rangers FC and Tottenham Hotspur.</p>
                <p>Plus loads of £10 and £20 vouchers that you can spend at supermarkets and retailers near you.</p>
                <p>Buy a participating product and enter today.</p>
              </div>
              <div className="home__cta">
                {renderEntryCta()}
              </div>
            </div>
          
        </>

      case 'NotStarted':
        return <>
          <div className="home__intro">
            <h2 className='type-h1'>Warm up for the ultimate matchday experience for you and your friends!</h2>
            <div className="goldbar"></div>
            <div className='home__intro-body'>
              <p>
              Get your squad together and keep a look out for our new promotion. We're giving you the chance to win YOUR ultimate match day experience including a luxury hotel stay, free travel, match tickets and a meet with a football legend for you, and 3 friends! It's all to play for.              </p>
              <p>
              Plus 100s of pairs of match day tickets up for grabs and vouchers to be won.              
              </p>
              <p>
              Sign up at Cadbury FC and get your family and friends ready for kick off - <br/><strong><span>January 6th 2025.</span></strong>     
              </p>  
            </div>
          </div>

          <div className="home__cta">

          <Link 
          analytics={{evnt: "button_click", category: "main_cadbury_site", location: "holding page introduction", label: "cadbury.co.uk newsletter sign up"}}
          to="https://www.cadbury.co.uk/cadburyfc/create-an-account/" lozenge title="Sign up now" target="_blank" >Sign up now</Link>

          </div>

        </>

      case 'Closed':
        return <>
          <div className="home__intro">

            <h2 className='type-h1 type-h1--white'>...AND THAT'S FULL TIME!</h2>
            <div className="goldbar"></div>
            <div className="home__intro-body">
              <p>
              The final whistle has blown on the 'The Winning Pass' promotion. For more chances to win match day tickets and other football goodies, sign up for a Cadbury FC account and select to receive emails. Good luck!              
              </p>
            </div>
          </div>
          <div className="home__cta">
          <Link 
         analytics={{evnt: "button_click", category: "main_cadbury_site", location: "closed competition introduction", label: "cadbury.co.uk site"}}
          to="https://www.cadbury.co.uk/cadburyfc/create-an-account/" lozenge title="Sign up" target="_blank" >Sign up</Link>


          </div>
        </>
    }
  }

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Cadbury FC - The Winning Pass</title>
      </Helmet>
      {competitionIsOpen &&
        <>
          <CodeEntry
            show={showEntryModal}
            onDismiss={() => setShowEntryModal(false)}
            onEntry={() => history.push("/prize-select") } 
          />
        </>
      }

      <h1 className="visually-hidden">Cadbury FC - The Winning Pass</h1>

        <section className="layout-wrapper home__banner">
          <div className="home__banner-container">
            <div className="home__banner-content">
              <KeyVisual />
            </div>
          </div>
        </section>

        <section className="layout-wrapper relative">
          <div className="layout-container layout-container--slim layout-container--center">

            {renderIntroMessage(compState)}
            
          </div>
        </section>

        {(competitionIsOpen) &&
          <section className="layout-wrapper home__prize-block-section">
              <div className="layout-container layout-container--slim layout-container--center">
                <h2 className="type-h1">Prizes</h2>
                <div className="goldbar"></div>
                <p><strong>This is your chance to win the ultimate matchday for YOU and three friends, including a luxury hotel stay, meet & greet with a club legend and match tickets.</strong></p>
                <p><strong>Pick from one of three experiences:</strong></p>
              </div>
              <div className="layout-container layout-container--wide layout-container--center">
                <div className="home__prize-blocks home__prize-blocks--gold">

                <Carousel 
                    className="home__prize-blocks_carousel"
                    params={{
                      watchOverflow: true,
                      spaceBetween: 30,
                      slidesPerView: 1,
                      centeredSlides: false,
                      pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                       },
                      navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                      },
                      runCallbacksOnInit: true,
                      slidesPerGroup: 1,
    
                      breakpoints: {
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                          slidesPerGroup: 1,
                        },
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 0,
                          slidesPerGroup: 3,
                        }
                      }
                    }}
                    >

                    <PrizeBlock
                      first
                      readOnly
                      image={VIPStar}
                      title={`<span>VIP</span> Match day`}
                      description={`<p>Want to rock up at your favourite club and have the red carpet rolled out? With the VIP experience you'll get the full hospitality treatment, stadium tour, and even go pitch side followed by VIP match tickets.</p>`}
                      alt="VIP Match Day experience"
                    />
                    <PrizeBlock
                      imageSmall
                      imageMiddle
                      readOnly
                      image={FANS}
                      title="The true fan experience"
                      description={`<p>Would you prefer to watch the game in and amongst the roaring crowds? Win £250 worth of food and drink vouchers and a stadium tour with a club legend, the true fan experience is for football fanatics that would prefer to stay in the thick of the game day action.</p>`}
                      alt="The true fan experience"
                    />
                    <PrizeBlock
                      imageSmall
                      lastChild
                      readOnly
                      image={GATES}
                      title="Behind the scenes day"
                      description={`<p>This is your chance to see everything that goes into a match day. Being an elite footballer takes a lot of preparation, experience this firsthand by touring some of the most exclusive areas and you'll get to enjoy VIP match tickets as well - score!</p>`}
                      alt="Behind the scenes day experience"
                    />

                  </Carousel>

                  <div className='home__prize-blocks__nav home__prize-blocks__nav--gold'>
                    <div className='swiper-pagination'></div>
                  </div>
                  
       
                </div>
            </div>
            <div className="home__prize-blocks home__prize-blocks--double">
                  
                  <PrizeBlockDouble
                    imageMob={Tickets}
                    imageMobLarge={Tickets2x}
                    image={TicketsDesk}
                    imageLarge={TicketsDesk2x}
                    image2Mob={Vouchers}
                    image2MobLarge={Vouchers2x}
                    image2={VouchersDesk}
                    image2Large={VouchersDesk2x}
                    title="PLUS HUNDREDS OF PAIRS OF MATCH TICKETS AND SHOPPING VOUCHERS TO BE WON!"
                  />
              
            </div>
          </section>
        }

      {
        (competitionIsOpen) &&
        <section className="layout-wrapper bg-secondary" id="how-to-play">
          <div className="layout-container layout-container--center layout-container--large">
            <h2 id="howitworks" className="home__howitworks type-h1">How do i enter?</h2>
            <div className="home__steps">
            {
              
              renderSteps([
                { number: 1, title: "Buy a participating product", image: How1, image2x: How1_2x, alt: "Cadbury products", descriptionLink: <><a href="/terms-and-conditions#products-list" rel="noopener noreferrer" target='_blank'>See full product list here</a></> },
                { number: 2, title: "Enter the barcode and batch code of your product", image: How2, image2x: How2_2x, alt: "A barcode and batch code from a Cadbury pack" },
                { number: 3, title: "Enter your details, select your favourite club", image: How3, image2x: How3_2x, alt: "Arsenal FC, Chelsea FC, Leeds United FC, Liverpool FC, Manchester United FC, and Tottenham Hotspurs FC badges." },
                { number: 4, title: "Pick your personalised matchday experience", image: How4, image2x: How4_2x, alt: "Pick your personalised matchday experience." },
                { number: 5, title: "Find out instantly if you've won match tickets or a shopping voucher", image: How5, image2x: How5_2x, alt: "The Winning Pass.", description: "The prize draw for the matchday experiences will take place after 21.03.25" }
              ])  
           
            }
            
            </div>
            <div className="home__cta">
              {renderEntryCta()}
            </div>
          </div>
        </section>
      }

      {
        (competitionIsNotStarted || competitionIsOpen) &&
        <SummaryTsandCs className={competitionIsOpen ? 'bg-secondary' : ''}/>
      }

    </div >
  );
}