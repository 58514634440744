import React from 'react';      
import classList from 'react-classlist-helper';
import { Parallax } from "react-parallax";
import FadeIn from 'components/FadeIn/FadeIn';

import './ThankYouKeyVisuals.scss';


export default (props) => {

  const classes = {
    "thankyou-keyvis": true
  }

  let parallaxMargin = 22;
  let parallaxPercentage = 80;
  let offsetParPercentage = 90;
  let strength = 100;
  var isDesk = window.matchMedia("only screen and (min-width: 640px)").matches;

  if (isDesk) {
    parallaxMargin = 0;
    strength = 100;
    parallaxPercentage = 140;
    offsetParPercentage = 140;
  }

  const offsetPercentage = (percentage) => {
    return percentage - offsetParPercentage;
  }

  return (
    <div className={classList(classes)}>
      <Parallax
        strength={strength}
        renderLayer={percentage => (
          <div className='thankyou-keyvis-wrapper'>
            <div className="thankyou-keyvis__bg"
              style={{
                transform: `translate3d(0, ${offsetPercentage((percentage * parallaxPercentage) + parallaxMargin)}px, 0)`,
              }}
            ></div>

          <FadeIn className='thankyou-keyvis__headline'>

            </FadeIn>

            <picture>
              <source type="image/png" srcSet={props.imageDesk2x + " 2x, " + props.imageDesk + " 1x"} media="(min-width:992px)" />
              <source type="image/png" srcSet={props.imageTab2x + " 2x, " + props.imageTab + " 1x"} media="(min-width:640px)" />
              <img src={props.image} srcSet={props.image2x + " 2x, " + props.image + " 1x"} alt={props.alt}
                className='thankyou-keyvis__players' />
            </picture>
          </div>

        )}
      />

    </div>

  )
}