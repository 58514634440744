import React from 'react';
import classList from 'react-classlist-helper';
import './SummaryTsandCs.scss';

const SummaryTsandCs = (props) => {
  const classMap = {
    "layout-wrapper": true,
    [props.className]: !!props.className,
  };

  return (
    <section className={`${classList(classMap)}`} >
      <div className="layout-container layout-container--center layout-container--wide layout-container--bottom-space">
        <div className='form__privacy form__privacy--reduced'>
        <div className='sumtsandcs'>
            <div className='title'>*Summary Terms and Conditions:</div>
            <p>UK, 18+ only. 10:00 06.01.2025 – 16:59 21.03.2025 (‘Promotion Period’). Purchase required. Internet &
            email required. <strong>Level 1 Prize Draw:</strong> Visit <a href="https://thewinningpass.cadbury.co.uk" rel="noopener noreferrer" target="_blank">thewinningpass.cadbury.co.uk</a> register your details, enter
            product barcode & batch code found on pack & select Participating Club*. <strong>Level 1 Prizes:</strong> 1 of 6 x
            Club Experiences with selected Club for winner & 3 guests incl. travel & accommodation. Level 1
            winners drawn at random via 6 prize draws (1 per Participating Club) at the end of the promotion. Max
            1 x Level 1 prize per household. <strong>Level 2 & 3 Winning Moments:</strong> All valid entries are submitted into a
            winning moments promotion. <strong>Level 2 Prizes:</strong> 201 x pairs of matchday tickets for the 2024/25 football
            season for Cadbury Partner Clubs**, subject to availability and club terms of stadium entry. The tickets
            are allocated week-by-week on a first come first served basis, as such the Promoter cannot guarantee
            that your first choice will be fulfilled. <strong>Level 3 Prizes:</strong> 375 x £20 Lifestyle vouchers and 500 x £10 Lifestyle
            vouchers. Level 2 and 3 winners are notified instantly on screen. Max 2 x Level 2 Prizes & max 3 x Level
            3 Prizes per household. 1 entry per person per day. Max 10 entries per person over the Promotion Period.
            Retain proof of purchase for each separate entry. All prizes are subject to applicable partner
            rules/regulations/guidance. Visit <a href="https://thewinningpass.cadbury.co.uk" rel="noopener noreferrer" target="_blank">thewinningpass.cadbury.co.uk</a> for full T&Cs & prize details. <strong>Promoter: </strong>
            Mondelez Europe Services GmbH – UK Branch, Sanderson Road, Uxbridge, UB8 1DH.</p>
            <p>*Participating Clubs defined as: Arsenal FC, Chelsea FC, Leeds United FC, Liverpool FC, Manchester
            United and Tottenham Hotspur.</p>
            <p>**Cadbury Partner Clubs defined as: Arsenal FC, Celtic FC, Chelsea FC, Leeds United FC, Liverpool FC,
            Manchester United, Rangers FC and Tottenham Hotspur.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SummaryTsandCs;
