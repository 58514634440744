import React from 'react';
import { CompetitionEntryContext, DEFAULT_STATE } from './CompetitionEntryContext';

export default () => {
    const [state, setState] = React.useContext(CompetitionEntryContext);

    function setEntering() {
        state.isEntering = true;

        setState(state);
    }

    function setEntryData(data) {
        state.barcode = data.barcode;
        state.batchCode = data.batchCode;
        state.retailer = data.retailer;
  
        setState(state);
    }

    function setCodes(data) {
        state.barcode = data.barcode;
        state.batchcode = data.batchcode;
        state.productType = data.productType;
        state.isEntering = true;
  
        setState(state);
    }

    function setResult(result) {
        state.result = result;

        setState(state);
    }

    function setPrize(prize) {
        state.prize = prize;
    }

    function setTeam(team) {
        state.team = team;
    }


    function setExperience(experience) {
        state.experience = experience;
    }

    function setEmail(email) {
        state.email = email;
    }

    function reset() {
        setState(DEFAULT_STATE);
    }

    return {
        ...state,
        setEntering,
        setEntryData,
        setCodes,
        setResult,
        setPrize,
        setTeam,
        setExperience,
        setEmail,
        reset
    };
}