import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Accordion from 'components/Accordion/Accordion';
import './FAQs.scss';
import { HashLink as Link } from 'react-router-hash-link';


export default (props) => {
  let defaultFaq = { section: 1, index: 0 };
  if (window.location.hash.length) {
    let values = window.location.hash.replace('#','').split('-');
    defaultFaq = {
      section: parseInt(values[0]),
      index: parseInt(values[1])
    }
  }

  const [accordion, setAccordion] = useState(defaultFaq);

  const handleAccordionClick = (index) => {
    if (index.index === accordion.index && 
        index.section === accordion.section) index = { section: 0, index: 0 };
    setAccordion(index);
  }

  const renderFaqs = (section, faqs) => {
    return faqs.map((faq, index) => {
      let id = { section, index };
      let key =  `${id.section}-${id.index}`;

      return (
        <Accordion
          id={key}
          key={key}
          open={`${accordion.section}-${accordion.index}` === key}
          onClick={handleAccordionClick.bind(this, id)}
          title={faq.question}
          answer={faq.answer}>
        </Accordion>
      )
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FAQs | Cadbury FC - The Winning Pass</title>
    </Helmet>
    <section className="layout layout-general layout-general--full layout-wrapper">
      <div className="layout-container layout-container--medium layout-container--center ">
        <h1 className="type-h1">FAQ<span className="lowercase">s</span></h1>
       {  <div className="faqs">
          <div className="faqs__section">
          <h2 className="type-h2">General Promotional Questions</h2>
            {
              renderFaqs(1, [{
              question: "1.	What is the ‘The Winning Pass’ promotion?",
              answer: `
                <p>
                  Cadbury’s ‘The Winning Pass’ is a promotion that gives you the chance to win your ultimate match day (from a choice of three match day experiences) with one of our six partner clubs: Arsenal FC, Chelsea FC, Leeds United, Liverpool FC, Manchester United or Tottenham Hotspur. What’s more is that you get to share it with three of your best friends. This is your chance to win match tickets, a UK hotel stay for you and your three guests, including travel, a meet and greet with a club legend and much more. Plus, there are hundreds of additional prizes to be won including matchday tickets and vouchers. For a full breakdown of the prizes please see the T&Cs page <a href="/terms-and-conditions" rel="noopener noreferrer" target="_blank">here</a>.
                </p>`
              },{
              question: "2.	Who is the promotion open to?",
              answer: `
                <p>
                  The promotion is open to anyone over 18 who lives in the UK, excluding the Channel Islands and the Isle of Man.
                </p>`
              },{
              question: "3.	How can I enter/ How does it work?",
              answer: `
                <p>
                  To enter, simply buy any eligible The Winning Pass product, head to <a href="https://thewinningpass.cadbury.co.uk" rel="noopener noreferrer" target="_blank">thewinningpass.cadbury.co.uk</a>, enter the barcode and batch code, select which club and ultimate match day you’d like to WIN and enter your details. The barcode and batch code can be found on the back of your participating promotional product. If you’d like to review the full terms and conditions and product details, please click <a href="/terms-and-conditions" rel="noopener noreferrer" target="_blank">here</a>.
                </p>
                <p>
                  You will find out instantly on screen if you have provisionally won a pair of match tickets or a shopping voucher (the win is provisional upon passing verification checks). You will also be entered into a prize draw for the chance to Win your Ultimate Match Day with your chosen club. The prize draw will take place within 5 working days after the promotion ends. The promotion ends on the 21st of March 2025.
                </p>
                `
              },{
              question: "4.	Which products are included in the promotion?",
              answer: `
                <p>
                  A wide range of Cadbury chocolate, Cadbury biscuits, Maynards Bassets sweets and Trebor mints are included in the promotion. For a full list of participating products please visit the T&Cs page <a href="/terms-and-conditions#products-list" rel="noopener noreferrer" target="_blank">here</a> and go to Appendix 1.
                </p>`
              },{
                question: "5.	When can I enter?",
                answer: `
                  <p>
                    The promotion runs from 10:00 GMT on 06/01/25 to 16:59 GMT on 21/03/25.
                  </p>`
              },{
                question: "6.	Do I need to keep my receipt for the participating product until the end of the promotion?",
                answer: `
                  <p>
                    Yes, proof of purchase may be required to verify your claim - so don’t throw it away.
                  </p>`
              },{
                question: "7.	Do I need internet access?",
                answer: `
                  <p>
                   You will need internet access to enter the promotion.
                  </p>
                  <p>
                   You also need a valid email address in order to claim a prize.
                  </p>
                  `
              }
              ])
            }
          </div>
          <div className="faqs__section">
            <h2 className="type-h2">Prize details, claiming and redemption</h2>
            {
              renderFaqs(2, [{
              question: "8.	What prizes are available?",
              answer: `
                <p>
                  There are 6 exclusive experiences up for grabs with Arsenal FC, Chelsea FC, Leeds United, Liverpool FC, Manchester United or Tottenham Hotspur for the winner and three guests. These each include match tickets for the winner and 3 guests, one night accommodation in two double occupancy rooms inclusive of breakfast (minimum 4* accommodation available on the night of OR night before the experience), return standard travel for all four guests, a meet and greet with a club legend and much more! There are also hundreds of club tickets for Arsenal FC, Celtic FC, Chelsea FC, Leeds United FC, Liverpool FC, Manchester United, Rangers FC and Tottenham Hotspur up for grabs (subject to availability), plus hundreds of retailer vouchers available. For a full prize breakdown head to section 12 of our T&Cs <a href="/terms-and-conditions#clause-12" rel="noopener noreferrer" target="_blank">here</a>.
                </p>`
              },{
              question: "9.	I didn’t win a Winning Moments prize on the website, but it says I’ve been entered into another draw, what is this?",
              answer: `
                <p>
                  Our Level 1 prizes are won via a draw from all valid entries at the end of the promotion - the draw you are entered into relates to the club specific prize draw you chose during the entry process. Each valid campaign entry equates to 1x entry into the prize draw. You can find more about this on the T&Cs page <a href="/terms-and-conditions" rel="noopener noreferrer" target="_blank">here</a>.
                </p>`
              },{
              question: "10.	How will I know if I have won?",
              answer: `
                <p>
                  If you have provisionally won a pair of match tickets or a retailer voucher you will be notified instantly on screen upon completion of the online entry form. You’ll then be contacted via email to confirm your identity and eligibility as well as your prize details, so keep an eye out and don’t forget to check your junk folder! If you are a Level 1 prize winner, we’ll contact you at the end of the promotion in line with our T&Cs <a href="/terms-and-conditions" rel="noopener noreferrer" target="_blank">here</a>.
                </p>`
              },{
              question: "11.	I didn’t receive my email for my Level 2 or Level 3 prize",
              answer: `
                <p>
                  If you do not receive your email following the online provisional win notification (remember to check your junk email inbox), please contact <a href="mailto:thewinningpass@promowinners.com" target="_blank" rel="noopener noreferrer">thewinningpass@promowinners.com</a>
                </p>`
              },{
                question: "12.	Is there a deadline for me to claim my Level 2/Level 3 prize?",
                answer: `
                  <p>
                    Yes, you must complete the online claim form in the email within 48 hours of initial contact for match tickets and 14 days of initial contact for vouchers. Please see section 13 in the <a href="/terms-and-conditions#clause-13" rel="noopener noreferrer" target="_blank">here</a> for further details.
                  </p>`
              }
              ])
            }
          </div>
          <div className="faqs__section">
            <h2 className="type-h2">On pack codes and entry mechanics</h2>
              {
                renderFaqs(3, [{
                question: "13. What codes do I need to enter to participate? ",
                answer: `
                  <p>
                    You must enter both the barcode and batch code as part of your entry, which can be found on the back of your promotional product. The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code found within the box on the back of your pack located directly under the “Best Before” date.
                  </p>`
                },{
                question: "14. I can’t read my code",
                answer: `
                  <p>
                    In some circumstances the batch code can be illegible or difficult to read. If you are having difficulties reading your code, please contact our customer service team on <a href="tel:+44800818181">0800 818 181</a>
 or at <a href="https://cadbury.co.uk/contact/" target="_blank" rel="noopener noreferrer">www.cadbury.co.uk/contact/</a> who will be able to assist you.
                  </p>`
                },{
                question: "15. My code isn’t working, am I entering the right code? ",
                answer: `
                  <p>
                    It can be confusing as bars often feature many codes on the back of the pack for production reasons. Please double check you are entering the correct code within the required form field.
                  </p>
                  <p>
                  The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code found within the box on the back of your pack located directly under the “Best Before” date.
                  </p>
                  <p>
                    Please ensure that there are no spaces before or after your barcode or batch code, as this may prevent it from being recognised by the site.
                  </p>
                  `
                },{
                question: "16.	I’ve already entered my code; can I enter it again for another chance to win?",
                answer: `
                  <p>
                    Each entry needs to be accompanied by a new purchase, as the same code cannot be entered multiple times. 
                  </p>`
                },{
                  question: "17.	My code has been declined online and I get an error message, what can I do?",
                  answer: `
                    <p><strong>If the error message is ‘This code is not recognised’</strong></p>
                    <p>
                      Please check that you’re trying to enter the right code. Bars often feature many codes on the back of the pack for production reasons. The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code found within the box on the back of your pack located directly under the “Best Before” date. 
                    </p>
                    <p>
                      Please ensure that there are no spaces before or after your barcode or batch code, as this may prevent it from being recognised by the site.
                    </p>
                    <p><strong>If the error message is ‘You've already entered today'</strong></p>
                    <p>
                    Remember, users are limited to one entry per person per email address per day. Only your first entry each day will be counted. Please note that there is a maximum of 10 entries per person during the entire Promotion Period.
                    </p>
                    `
                },{
                  question: "18.	I entered the wrong email address. What should I do?",
                  answer: `
                    <p>
                      Please be careful when entering your details as once you have made an entry your details cannot be changed.  
                    </p>`
                }
              ])
            }
          </div>
         
        </div>}  
      </div>
    </section>
  </>;
}