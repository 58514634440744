import VIPStar from 'assets/images/campaign/VIP.png';
import VIPStar2x from 'assets/images/campaign/VIP@2x.png';
import FANS from 'assets/images/campaign/FANS.png';
import FANS2X from 'assets/images/campaign/FANS@2x.png';
import GATES from 'assets/images/campaign/GATES.png';
import GATES2X from 'assets/images/campaign/GATES@2x.png';



const experienceList = [
  { 
    "id": "VIP", 
    "image": VIPStar, 
    "imageLarge": VIPStar2x, 
    "title": "<span>VIP</span>Match Day",
    "description": "Want to rock up at your favourite club and have the red carpet rolled out? With the VIP experience you'll get the full hospitality treatment, stadium tour, and even go pitch side and of course followed by VIP match tickets."
  },
  { 
    "id": "FAN", 
    "image": FANS,
    "imageLarge": FANS2X,  
    "title": "The True Fan Experience",
    "description": "Would you prefer to watch the game in and amongst the roaring crowds? With £250 worth of food and drink vouchers and a legend stadium tour, the true fan experience is for football fanatics that would prefer to stay in the thick of the game day action."

  },
  { 
    "id": "TOUR", 
    "image": GATES, 
    "imageLarge": GATES2X, 
    "title": "Behind the Scenes Day",
    "description": "This is your chance to see all that goes into a matchday, and see firsthand the preparation it takes to be an elite footballer. You will get to tour some of the most exclusive areas and you'll also get to enjoy your VIP match tickets."

  }

]

export default experienceList;