import React, { useEffect, useState } from 'react';

import useSettingsContext from 'contexts/useSettingsContext';
import signupService from 'services/signupService';
import signupValidator from 'validators/signupValidator';

import Button from 'components/Button/Button';
import Link from 'components/Link/Link';
import TextField from 'components/Forms/TextField';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.svg';

import Carousel from 'components/Carousel/Carousel';

import Img1 from 'assets/images/campaign/placeholder1.png';
import Img1Dbl from 'assets/images/campaign/placeholder1@2x.png';
import Img2 from 'assets/images/campaign/placeholder2.png';
import Img2Dbl from 'assets/images/campaign/placeholder2@2x.png';
import Img3 from 'assets/images/campaign/placeholder3.png';
import Img3Dbl from 'assets/images/campaign/placeholder3@2x.png';

import Form, { createOnChangeHandler } from './Form';

import './Form.scss';
import './SignUp.scss';

export default (props) => {
  let settings = useSettingsContext();

  let [sent, setSent] = useState(false);
  let [confirmed, setConfirmed] = useState(false);
  let [email, setEmail] = useState(props.email);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [sent]);

  const createCommand = () => {
    return {
      email,
      competitionEntryPublicId: props.competitionEntryPublicId,
      recaptchaToken: settings.recaptchaToken
    };
  }

  const onFormSuccess = (d) => {
    setSent(true);
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
  }

  const onConfirmModal = () => {
    setConfirmed(true);
  }


  /* RENDER */
  if (!sent) {
    return (
      <div className="signup">
        <div className="signup__body">
          <div className="type-h1 type-h1--purple">{props.title}</div>
          <p> {props.description} </p>

          <Carousel
          className="signup__image-wrapper"
          params={{
            spaceBetween: 0,
            slidesPerView: 1,
            centeredSlides: false,
            pagination: false,
            navigation: false,
            loop: true,
            autoplay: {
              "delay": 2500,
              "disableOnInteraction": false
            },
            breakpoints: {
              768: {
                slidesPerView: 3,
                spaceBetween: 4,
                autoplay: false,
                loop: false,
                allowSlideNext: false,
                allowSlidePrev: false
              }
            }
          }}
        >

          <div className="signup__image">
            <img src={Img1} srcSet={Img1Dbl + " 2x, " + Img1 + " 1x"} alt="Fan taking a selfie with Harry Kane" />
          </div>
          <div className="signup__image">
            <img src={Img2} srcSet={Img2Dbl + " 2x, " + Img2 + " 1x"} alt="Young fan meeting Neymar Jr" />
          </div>
          <div className="signup__image">
            <img src={Img3} srcSet={Img3Dbl + " 2x, " + Img3 + " 1x"} alt="Young fans meeting Virgil van Dijk" />
          </div>

        </Carousel>

        <div className="signup__cta_container">
          {
            props.isLogged ?
              (   <Link
                analytics={{evnt: "button_click", category: "main_cadbury_site", location: "thank you page", label: "cadbury fc site"}}
                  href="https://www.cadbury.co.uk/cadburyfc/"
                  target="_blank"
                  className="signup__cta" lozenge >
                  Explore Cadbury FC
                </Link>
                
              ) : (
                  <Link
                  analytics={{evnt: "button_click", category: "main_cadbury_site", location: "thank you page", label: "cadbury fc - create an account site"}}
                  href="https://www.cadbury.co.uk/cadburyfc/create-an-account/"
                  target="_blank"
                  className="signup__cta" lozenge >
                  Create an account
                </Link>
                
              )
          }
        </div>

          <Form
            id="reminder"
            submitTitle={props.submitText || "Submit"}
            onSuccess={onFormSuccess}
            command={{
              model: createCommand,
              service: signupService.addSignup
            }}
            validation={{
              validator: signupValidator,
              onValidation: setValidation
            }}
          >

            {validation.errors.hasErrors && validation.acknowledged &&
              <ValidationSummary
                errors={validation.errors}
                handlePropertyErrors={['RecaptchaToken', 'optIn']}
              />
            }

            <fieldset className="form__fieldset">
              <TextField
                label="Email"
                id="email"
                onChange={createOnChangeHandler(validation, 'email', setEmail)}
                value={email}
                hasErrors={!!validation.errors.email}
              />
              <ValidationMessage errors={validation.errors.email} />
            </fieldset>

            <ReCaptcha captchaSettings={settings.captchaSettings} />

            {validation.errors.hasErrors && !validation.acknowledged &&
              <Modal
                panel
                hideClose
                icon={AlertIcon}
                alt="Warning sign"
                onDismiss={onDismissModal}
                onConfirm={onDismissModal}
                title="Oops">
                <ValidationSummary
                  modal
                  defaultMessage="Please check you have entered all fields correctly."
                  errors={validation.errors}
                  handlePropertyErrors={['RecaptchaToken', 'optIn']}
                />
                {!validation.errors.globalErrors.length ? (
                  <Button title="Try again" onClick={onDismissModal} />
                ) : (
                  <Button title="Back to homepage" href="/" />
                )
                }
              </Modal>
            }
          </Form>
          
        </div>
      </div>
    )
  } else if (!confirmed) {
    return (
      <Modal
        panel
        onDismiss={onConfirmModal}
        title="Thanks for signing up!">
        <p>Your recipe cards will be landing in your inbox soon.</p>
      </Modal>
    )
  } else {
    return null;
  }
}