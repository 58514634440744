import React, { useEffect, useState } from 'react';
import classList from 'react-classlist-helper';
import './PrizeBlock.scss';

const PrizeBlock = (props) => {
  const mainClassMap = {
    "prize-block": true,
    "prize-block--select": props.select,
    "prize-block--selectable": props.selectable,
    "prize-block--bordered": props.selected 
  };

  const imageClassMap = {
    "prize-block__image": true,
    "prize-block__image--small": props.imageSmall,
    "prize-block__image--middle": props.imageMiddle,
    "prize-block__image--last": props.lastChild,
    "prize-block__image--contain": props.containImage
  };

  const wrapperClassMap = {
    "prize-badge-wrapper": true,
    "prize-badge-wrapper--bordered": props.selected 
  };

  
  const onChange = (e, id) => {
  
    if (props.onChange) {
      props.onChange(e, props.prize.id);
    }

  }

  return (
    <div className={classList(wrapperClassMap)}>
      <div className={classList(mainClassMap)}>
        {props.image && props.imageLarge && (
          <div className={classList(imageClassMap)}>
            <img src={props.image} srcSet={`${props.imageLarge} 2x, ${props.image} 1x`} alt={props.alt ? props.alt : props.title} />
          </div>
        )}
        {props.image && !props.imageLarge && (
          <div className={classList(imageClassMap)}>
            <img src={props.image} alt={props.alt ? props.alt : props.title} />
          </div>
        )}
        <div className="prize-block__info">
          {props.title && (
            <h3 className={props.first ? "prize-block__title prize-block__title--first type-h1 type-h1--purple" : "prize-block__title type-h1 type-h1--purple"} dangerouslySetInnerHTML={{ __html: props.title }}></h3>
          )}
          {props.description && (
            <div className={!props.readOnly ? "prize-block__info-copy prize-block__info-copy--mb" : "prize-block__info-copy"} dangerouslySetInnerHTML={{ __html: props.description }}></div>
          )}
        </div>

        {!props.readOnly && (
          <>
          <input
            className="prize-block__radio"
            type="radio"
            id={props.prize.id}
            name={props.model}
            value={props.prize.id}
            checked={props.selected}
            onChange={(e) => onChange(e, props.prize.id)} 
            tabIndex={props.tab}
          />
          <div className='prize-block__fake-radio'>
          <div className="prize-block__tick"></div>
          </div>
          </> 
        )}
        <div className='prize-block__layer'></div>
      </div>
    </div>
  );
};

export default PrizeBlock;
