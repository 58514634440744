import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Claim from './Claim';
import useSettingsContext from 'contexts/useSettingsContext';
import OTPClaim from 'containers/OneTimePassword/OTPClaim';
import useEffectOnce from 'hooks/useEffectOnce';

import prizeClaimService from 'services/prizeClaimService';

export default () => {
  let { token } = useParams();
  let settings = useSettingsContext();
  let [claimCreatedDate, setClaimCreatedDate] = useState("");
  let [otpClaimStartDate, setOtpClaimStartDate] = useState("");  
  let [pageStage, setPageState] = useState(""); 

  let otpClaimAvilable = settings.otpClaimEnabled === "True" ? true : false;


  useEffectOnce(() => {
    async function fetchData() {
      prizeClaimService.getPrizeClaim(token).then((data) => {        
        if(data) {
          setClaimCreatedDate(new Date(data.createDate));
          setOtpClaimStartDate(new Date(data.otpStartDate));          
        } 
        setPageState("fetched");

      });
    }

    fetchData();

  });


  if(pageStage !== "loading") {
    if(otpClaimAvilable && token) {
      return (
        <OTPClaim 
          token={token}
        />
      )
    } else if (token) {
      return (
        <Claim 
          otpAvilable={false}
          token={token}
        />
      )
    } else {
    return <Redirect to='/' />
    }
  } else {
    return (
      <div>Loading...</div>
    )
  }

    /*To check OTP Claim route use this

    if(pageStage !== "loading") {
      return (
        <OTPClaim 
          //token={token}
        />
      )
    }*/

    /*To check Claim route use this

    if(pageStage !== "loading") {
      return (
        <OTPClaim 
          //token={token}
        />
      )
    }*/

}
