import ValidationErrorCollection from './ValidationErrorCollection';

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const ALPHA_REGEX = /^[A-Za-z]+$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('email', 'Please enter your email address');
  required('password', 'Please enter your password');
  //required('country', 'Please enter your country');
  //required('recaptcha2', 'Please confirm you are not a robot');

  if (command.email && !EMAIL_REGEX.test(command.email)) {
    messages.addError('email', 'Please enter a valid email address');
  }

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
