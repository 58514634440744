import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Link from 'components/Link/Link';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import analyticsService from 'services/analyticsService';
import ThankYouKeyVisuals from './ThankYouKeyVisuals';
import SignUp from 'containers/Forms/SignUp';
import useEffectOnce from 'hooks/useEffectOnce';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';
import NpsBanner from 'components/NpsBanner/NpsBanner';
import timer from 'utils/timer';



//voucher images
import thankyoukeyvis_voucher10_dt from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher10_dt.png';
import thankyoukeyvis_voucher10_dt_2x from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher10_dt@2x.png';
import thankyoukeyvis_voucher10_tab from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher10_tab.png';
import thankyoukeyvis_voucher10_tab_2x from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher10_tab@2x.png';
import thankyoukeyvis_voucher10_mob from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher10_mob.png';
import thankyoukeyvis_voucher10_mob_2x from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher10_mob@2x.png';

import thankyoukeyvis_voucher20_dt from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher20_dt.png';
import thankyoukeyvis_voucher20_dt_2x from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher20_dt@2x.png';
import thankyoukeyvis_voucher20_tab from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher20_tab.png';
import thankyoukeyvis_voucher20_tab_2x from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher20_tab@2x.png';
import thankyoukeyvis_voucher20_mob from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher20_mob.png';
import thankyoukeyvis_voucher20_mob_2x from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_voucher20_mob@2x.png';

import thankyoukeyvis_tickets_dt from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_tickets_dt.png';
import thankyoukeyvis_tickets_dt_2x from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_tickets_dt@2x.png';
import thankyoukeyvis_tickets_tab from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_tickets_tab.png';
import thankyoukeyvis_tickets_tab_2x from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_tickets_tab@2x.png';
import thankyoukeyvis_tickets_mob from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_tickets_mob.png';
import thankyoukeyvis_tickets_mob_2x from 'assets/images/campaign/keyvis/thankyou/thankyoukeyvis_tickets_mob@2x.png';

import './Thankyou.scss';


export default () => {
  let { entryPublicId } = useParams();


  let [settings, setSettings] = React.useContext(SettingsContext);
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";

  let competitionEntryContext = useCompetitionEntryContext();
  let [entry, setEntry] = useState(competitionEntryContext.result);
  let [showNps, setShowNps] = useState(null);


  useEffect(() => {
    async function fetchData() {
      await competitionEntryService.getCompetitionEntry(entryPublicId)
      .then((result) => {       
          if(result) {
            setEntry(result);
            if(result.hasCustomerSurveyScore) {              
              setShowNps(false);
            } else {              
              timer.delay(2500).then(() => setShowNps(true));
            }

            var email = competitionEntryContext.email;
            //Had to go here, as not triggered on last page
            if (email != null) {
              // Save email to DataLayer - GTM uses this to pass to Lytics (Only if the user has accepted targeting cookies)

              analyticsService.logEvent("signup", "entry_registration", {
                location: "thankyou_page",
                label: "email capture",
                url: email
              });

              analyticsService.logEvent("signup", "entry_registration", {
                location: "thankyou_page",
                label: "registration success"
              });

              if (result.hasWon) {
                analyticsService.logEvent('signup', 'entry_registration', {
                  location: "thankyou_page",
                  label: "prize",
                  url: result.prize.category.publicId
                });
              }
            }

          } 
      })
      .catch(err => console.log(err) );
    }

    if (entryPublicId && !entry) {
      fetchData();
    }

  }, [entry]);

 
  if (competitionIsOpen && ((entry !== null && !entry.entryPublicId) || !entryPublicId)) {
    return (
      <Redirect to="/" />
    )
  }

  const renderSignupComponent = (isLogged) => {
    return (
      <div className="thankyou__signup">
        <SignUp
          competitionEntryPublicId={entryPublicId}
          email={competitionEntryContext.email}
          isLogged={isLogged}
          title="More chances to win"
          description={
            isLogged ?
              (`Head over to Cadbury FC to see what other prizes you can win with your Cadbury FC account throughout the year.`)
              :
              (`Create a Cadbury FC account for additional chances to WIN match day tickets and more, plus receive email updates with the latest news and competitions. It's quick to set up an account - we can use the details you've just shared.`)
          }
          submitText="Errm, yes please"
        />
      </div>
    )
  }

  const renderNpsBanner = (entryPublicId) => {
    if (!entryPublicId || entry.hasCustomerSurveyScore) return null;
    return <NpsBanner onClose={() => setShowNps(false)} competitionEntryPublicId={entryPublicId} show={showNps} />
  }

  const renderWinnerImage = (prizeTier) => {
    return (
      <>
        {
          {
            "1":
              <ThankYouKeyVisuals
              image={thankyoukeyvis_tickets_mob}
              image2x={thankyoukeyvis_tickets_mob_2x}
              imageTab={thankyoukeyvis_tickets_tab}
              imageTab2x={thankyoukeyvis_tickets_tab_2x}
              imageDesk={thankyoukeyvis_tickets_dt}
              imageDesk2x={thankyoukeyvis_tickets_dt_2x}
              alt="Congratulations, you've won Match Day tickets!"
              />,
            "2":
              <ThankYouKeyVisuals
              image={thankyoukeyvis_voucher10_mob}
              image2x={thankyoukeyvis_voucher10_mob_2x}
              imageTab={thankyoukeyvis_voucher10_tab}
              imageTab2x={thankyoukeyvis_voucher10_tab_2x}
              imageDesk={thankyoukeyvis_voucher10_dt}
              imageDesk2x={thankyoukeyvis_voucher10_dt_2x}
              alt="Congratulations, you've won a £10 e-voucher!"
              />,
              "3":
              <ThankYouKeyVisuals
              image={thankyoukeyvis_voucher20_mob}
              image2x={thankyoukeyvis_voucher20_mob_2x}
              imageTab={thankyoukeyvis_voucher20_tab}
              imageTab2x={thankyoukeyvis_voucher20_tab_2x}
              imageDesk={thankyoukeyvis_voucher20_dt}
              imageDesk2x={thankyoukeyvis_voucher20_dt_2x}
              alt="Congratulations, you've won a £20 e-voucher"
              />
          }[prizeTier]
        }
      </>
    );
  }

  const renderWinnerText = (prizeTier) => {
    return (
      <>
        {
          {
            "1":
              <>
              <h1 className="type-h1">Goal!!!</h1>
              <div className="goldbar"></div>
              <p className="thankyou__para"><strong>You've won a pair of match tickets (make sure you validate your entry within 48 hours via the email we're sending you to make it official!)</strong></p>
              <p className="thankyou__para">You've also been entered into the prize draw for The Winning Pass, your chance to win your Ultimate Match Day, a luxury hotel stay and a meet and greet with a club legend.</p>
              <p className="thankyou__para">The draw will take place in March 2025. Fingers crossed you score again!</p>
              <p className="thankyou__para">To validate your entry and claim your tickets we're sending you a confirmation email to the email address you registered with. If you don't validate your entry by clicking the unique link in the email and completing your claim details within <strong>48 hours</strong> from the date of this online win notification, unfortunately, you will forfeit your prize, so don't forget to check your junk folder! If you don't receive an email please contact <a href="mailto:thewinningpass@promowinners.com"  target='_blank' rel='noopener noreferrer'>thewinningpass@promowinners.com</a></p>
              </>,
            "2":
              <>
              <h1 className="type-h1">Back of the net!</h1>
              <div className="goldbar"></div>
              <p className="thankyou__para"><strong>You've won a £10 voucher! (make sure you validate your entry within 14 days via the email we're sending you to make it official!)</strong></p>
              <p className="thankyou__para">You've also been entered into the prize draw for The Winning Pass, your chance to win your Ultimate Match Day, a luxury hotel stay and a meet and greet with a club legend.</p>
              <p className="thankyou__para">The draw will take place in March 2025. Fingers crossed you score again!</p>
              <p className="thankyou__para"> To validate your entry and claim your voucher you'll be sent a confirmation email to the email address you registered with. If you don't validate your entry by clicking the unique link in the email and completing your claim details within <strong>14 days</strong> from the date of this online win notification, unfortunately, you will forfeit your prize, so don't forget to check your junk folder! If you do not receive an email please contact <a href="mailto:thewinningpass@promowinners.com"  target='_blank' rel='noopener noreferrer'>thewinningpass@promowinners.com</a></p>
              </>,
              "3":
              <>
              <h1 className="type-h1">Back of the net!</h1>
              <div className="goldbar"></div>
              <p className="thankyou__para"><strong>You've won a £20 voucher! (make sure you validate your entry within 14 days via the email we're sending you to make it official!)</strong></p>
              <p className="thankyou__para">You've also been entered into the prize draw for The Winning Pass, your chance to win your Ultimate Match Day, a luxury hotel stay and a meet and greet with a club legend.</p>
              <p className="thankyou__para">The draw will take place in March 2025. Fingers crossed you score again!</p>
              <p className="thankyou__para"> To validate your entry and claim your voucher you'll be sent a confirmation email to the email address you registered with. If you don't validate your entry by clicking the unique link in the email and completing your claim details within <strong>14 days</strong> from the date of this online win notification, unfortunately, you will forfeit your prize, so don't forget to check your junk folder! If you do not receive an email please contact <a href="mailto:thewinningpass@promowinners.com"  target='_blank' rel='noopener noreferrer'>thewinningpass@promowinners.com</a></p>
              </>
          }[prizeTier]
        }
      </>
    );
  }


  const renderWinner = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Congratulations! | Cadbury FC - The Winning Pass</title>
        </Helmet>

          {renderWinnerImage(entry.prize.publicId)}

        <section id="confetti-container" className="layout-wrapper thankyou">
          <div className="layout-container layout-container--center layout-container--slim">

          {renderWinnerText(entry.prize.publicId)}

          </div>

          <div className="layout-container layout-container--center layout-container--medium winner">  

            {renderSignupComponent(settings.isLoggedIn)}

          </div>

          <SummaryTsandCs/>

          {renderNpsBanner(entryPublicId)}

        </section>
      </>
    );

  }

  const renderLoser = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sorry | Cadbury FC - The Winning Pass</title>
        </Helmet>

        <section className="layout-general layout-wrapper layout-general--full thankyou">
          <div className="layout-container layout-container--center layout-container--slim">
            <h1 className="type-h1">You're not a winner...yet</h1>
            <div className="goldbar"></div>
            <p className="thankyou__para">
            You haven't won anything just yet, but you have been entered into the draw for The Winning Pass, your chance to win your Ultimate Match Day, a luxury hotel stay and a meet and greet with a club legend. The draw will take place in March 2025.</p>
            <p className="thankyou__para">
            And remember, you can enter again tomorrow for another shot at scoring match tickets and vouchers. Good luck!            
            </p>
          </div>
          <div className="layout-container layout-container--center layout-container--medium loser">  

            {renderSignupComponent(settings.isLoggedIn)}

          </div>

          <SummaryTsandCs/>

          {renderNpsBanner(entryPublicId)}
        </section>

      </>
    );
  }

  return (
    <>
    {
      (entryPublicId && !entry) ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Error | Cadbury FC - The Winning Pass</title>
          </Helmet>
          <section className="layout-general layout-wrapper">
            <div className="layout-container layout-container--center layout-general--full thankyou">
              <h1 className="type-h2">Loading!</h1>
              <Link title="Back to homepage" 
                analytics={{evnt: "button_click", category: "internal_link", location: "thank you page - loading", label: "home page"}}
                lozenge route to="/">Back to home</Link>
            </div>
          </section>
        </>
      ) : (
        entry.hasWon ? (
            renderWinner()
        ) : (
            renderLoser()
        ) 
      )
    }

    </>

  );
}
