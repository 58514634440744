import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classList from 'react-classlist-helper';
import FocusTrap from 'focus-trap-react';
import useSettingsContext from 'contexts/useSettingsContext';
import accountService from 'services/accountService';
import Link from 'components/Link/Link';


import analyticsService from 'services/analyticsService';

import Logo from 'assets/images/themes/cadburyfc/cadburyfc-logo.png';
import Logo_2x from 'assets/images/themes/cadburyfc/cadburyfc-logo@2x.png';
import Arsenal from 'assets/images/themes/cadburyfc/badges/club-arsenal.svg';
import Chelsea from 'assets/images/themes/cadburyfc/badges/club-chelsea.svg';
import Leeds from 'assets/images/themes/cadburyfc/badges/club-leeds.png';
import Liverpool from 'assets/images/themes/cadburyfc/badges/club-lfc.svg';
import ManchesterUnited from 'assets/images/themes/cadburyfc/badges/club-manutd2x-2large.png';
import Tottenham from 'assets/images/themes/cadburyfc/badges/club-th.svg';

import './Header.scss';

let lastScroll = 0;
let direction = 0;
let menuOpen = false;
let ticking = false;

export default function (props) {
  const settings = useSettingsContext();
  const history = useHistory();
  const [hide, setHide] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const menuStateInput = useRef();
  const location = useLocation();

  let [simpleHeader, setSimpleHeader] = useState(false);

  /*useEffect(() => {

    let currpathname = location.pathname.toLowerCase();

    if(currpathname.indexOf("registration") > -1 || 
      currpathname.indexOf("claim") > -1 || 
      currpathname.indexOf("forgotten-password") > -1 || 
      currpathname.indexOf("login") > -1 ||
      currpathname.indexOf("reset") > -1 ) {
      setSimpleHeader(true);
    } else {
      setSimpleHeader(false);
    }

  }, [location]);*/

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!navOpen) {
      document.getElementsByTagName('body')[0].removeAttribute("style");
    } else {
      document.getElementsByTagName('body')[0].style.overflow = "hidden";
    }
  }, [navOpen]);

  useEffect(() => {
    if (menuStateInput && menuStateInput.current) {
      menuStateInput.current.addEventListener("keyup", onKeyUp);
      return () => menuStateInput.current.removeEventListener("keyup", onKeyUp);
    }
  }, []);

  const onScroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        handleScroll();
        ticking = false;
      });
      ticking = true;
    }
  }

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  }

  const handleScroll = async () => {
    let scroll = getScroll();
    direction = (scroll > lastScroll) ? 1 : -1;

    if (menuStateInput.current) {
      menuOpen = await getMenuState();
    }

    setHide(direction === 1 && !menuOpen && scroll > 150);
    lastScroll = scroll;
  }

  const getMenuState = async () => {
    return menuStateInput.current.checked;
  }

  const getScroll = () => {
    return window.scrollY || window.pageYOffset;
  }

  const handleClick = () => {
    menuStateInput.current.click();
  }

  const handleAuth = async () => {
    menuStateInput.current.click();

    if (settings.isLoggedIn) {
      try {
        await accountService.logout();
        props.onLogout();
      } catch { }
    } else {
      history.push('/login');
    }
  }

  const onInputChange = (e) => {
    setNavOpen(e.currentTarget.checked);

    if(e.currentTarget.checked) {
      analyticsService.logEvent("menu_click", "top_menu", {
        location: "menu",
        label: "open menu",
        url: ""
      });
    } else {
      analyticsService.logEvent("menu_click", "top_menu", {
        location: "menu",
        label: "close menu",
        url: ""
      });
    }
  }


  const renderStaticBadges = () => {

    return (
      <div className="header__logos">
        <div className="header__logo-group">
          <div className="header__logo-badge">
            <img src={Arsenal} alt="Arsenal FC badge" />
          </div>
          <div className="header__logo-badge">
            <img src={Chelsea} alt="Chelsea FC badge" />
          </div>
          <div className="header__logo-badge">
            <img src={Leeds} alt="Leeds FC badge" />
          </div>
        </div>
        <div className="header__logo-group">
          <div className="header__logo-badge">
            <img src={Liverpool} alt="Liverpool FC badge" />
          </div>
          <div className="header__logo-badge">
            <img src={ManchesterUnited} alt="Manchester United badge" />
          </div>
          <div className="header__logo-badge">
            <img src={Tottenham} alt="Tottenham Hotspur badge" />
          </div>
        </div>
      </div>
    )
  }


  const classMap = {
    "header": true,
    "header--hide": hide,
    "header--navopen": navOpen,
    "header--simple": simpleHeader,
    [props.className]: !!props.className,
  };

  return (
    <header className={classList(classMap)}>

      <Link route className="header__logo" to="/"
      analytics={{ evnt: "image_click_logo", category: "logo", location: "site_header", label: "" }}>
        <img src={Logo} srcSet={Logo_2x + " 2x, " + Logo + " 1x"} className="header__svg" alt="Cadbury FC" />
      </Link>

      <div className="header__clubs">
        {renderStaticBadges()}
      </div>

      {props.enableMenu &&

        <FocusTrap active={navOpen}>
          <nav className="header__nav">
            <div className="header__menuToggle">
              <input type="checkbox" ref={menuStateInput} onChange={onInputChange} />
              <span className='header__menuToggle__focus'></span>
              <span className='header__menuToggle__span'></span>
              <span className='header__menuToggle__span'></span>
              <span className='header__menuToggle__span'></span>
              <div className="header__menu">
                <ul className="header__menu__nav">
                  <li><Link
                    analytics={{evnt: "menu_click", category: "top_menu", location: "menu", label: "home page"}}
                    to="/"
                    route
                    title="Home"
                    className="header__link"
                    cancelFocus={!navOpen}
                    onClick={handleClick} >
                    Home
                  </Link></li>

                  <li><Link
                    analytics={{evnt: "menu_click", category: "top_menu", location: "menu", label: "frequently asked questions page"}}
                    to="/faqs"
                    route
                    title="Frequently Asked Questions"
                    className="header__link"
                    cancelFocus={!navOpen}
                    onClick={handleClick} >
                    FAQ<span className="lowercase">s</span>
                  </Link></li>

                  <li><Link
                    analytics={{evnt: "menu_click", category: "top_menu", location: "menu", label: "terms and conditions page"}}
                    to="/terms-and-conditions"
                    route
                    title="Terms and Conditions"
                    className="header__link"
                    cancelFocus={!navOpen}
                    onClick={handleClick} >
                    T&amp;C<span className="lowercase">s</span>
                  </Link></li>

                  {settings.isLoggedIn &&
                    <li className="header__menu__nav__divider">
                      <Link
                        analytics={{evnt: "menu_click", category: "logout", location: "menu", label: "logout"}}
                        route
                        title="Log out"
                        className="header__link header__link--md"
                        cancelFocus={!navOpen}
                        onClick={handleAuth} >
                        Log out
                      </Link></li>
                  }

                </ul>
                
                  <div className="header__menu__footer">
                    <Link
                      analytics={{evnt: "link_click", category: "top_menu", location: "menu", label: "cadbury.co.uk/cadburyfc"}}
                      cancelFocus={!navOpen} className="header__menu__footer-link"
                      href="https://www.cadbury.co.uk/cadburyfc/" onClick={handleClick}
                      target="_blank" >
                      Cadbury FC
                    </Link>
                  </div>
              
              </div>
            </div>
            <div className="header__overlay" onClick={handleClick}></div>
          </nav>
        </FocusTrap>

      }
    </header>
  );
}