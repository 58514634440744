import React from 'react';
import classList from 'react-classlist-helper';
import './Button.scss';
import analyticsService from 'services/analyticsService';

export default (props) => {
  const classMap = {
    "button": true,
    "button--wide": !!props.wide,
    "button--text-link" : !!props.analytics,
    "button--purple" : !!props.alt,
    [props.className]: !!props.className,
  };

  const handleOnClick = () => {

    if(props.analytics !== undefined && props.analytics !== null){
      let analytics = props.analytics;
      
      analyticsService.logEvent(analytics.evnt, analytics.category, {
        location: analytics.location ? analytics.location : "",
        label: analytics.label ? analytics.label : "",
        url: analytics.url ? analytics.url : ""
      });
    }

    if(props.onClick){
      props.onClick();
    }
  }

 if (props.href) {
    return (
      <a 
        className={classList(classMap)}
        href={props.href} 
        title={props.title}
      >
        <span> {props.title}</span>
      </a>
    )
  } else {
    return (
  
      <button 
        id={props.id || ""}
        type={props.type ||"button"}
        className={classList(classMap)}
        disabled={props.disabled}
        onClick={handleOnClick}>
          <span>{props.title}</span>
      </button>
    );
  }
}